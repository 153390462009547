<template>
    <div id="filed">
        <a-page-header style="border-bottom: 2px solid rgb(235, 237, 240); margin-bottom: 10px;" title="归档查询" sub-title="查询归档的投诉与举报" />
        <a-card title="归档查询" style="text-align: left;">
            <a-row>
                <a-col span="1" class="center" style="padding: 5px;">地址</a-col>
                <a-col span="9" class="center" style="padding: 5px;">
                    <a-input placeholder="请填入查询地址" v-model:value="searchLocation" ></a-input>
                </a-col>
                <a-col span="1" class="center" style="padding: 5px;">日期</a-col>
                <a-col span="3" class="center" style="padding: 5px;">
                    <a-date-picker style="width: 100%;" v-model:value="searchDate" placeholder="请选择日期" />
                </a-col>
                <a-col span="1" class="center" style="padding: 5px;">状态</a-col>
                <a-col span="3" class="center" style="padding: 5px;">
                    <a-cascader v-model:value="searchStatus" :options="statusOptions" :allowClear="false" placeholder="请设置状态" />
                </a-col>
                <a-col span="1" class="center" style="padding: 5px;">ID</a-col>
                <a-col span="5" class="center" style="padding: 5px;">
                    <a-input placeholder="请填入查询ID" v-model:value="searchID" ></a-input>
                </a-col>
            </a-row>
            <a-row>
                <a-col span="1" class="center" style="padding: 5px;">类型</a-col>
                <a-col span="3" class="center" style="padding: 5px;">
                    <a-cascader v-model:value="searchType" :options="typeOption" :allowClear="false" placeholder="请选择类型" />
                </a-col>
                <a-col span="1" class="center" style="padding: 5px;">匿名</a-col>
                <a-col span="3" class="center" style="padding: 5px;">
                    <a-cascader v-model:value="searchAnonymous" :options="anonymousOption" :allowClear="false" placeholder="请选择类型" />
                </a-col>
                <a-col span="1" class="center" style="padding: 5px;">电话</a-col>
                <a-col span="5" class="center" style="padding: 5px;">
                    <a-input placeholder="请填入联系电话" v-model:value="searchPhone" :disabled="searchAnonymous == 1" ></a-input>
                </a-col>
                <a-col span="4" offset="4" class="center" style="padding: 5px;">
                    <a-button type="primary" @click="">查询</a-button>
                </a-col>
            </a-row>
        </a-card>
        <a-divider />
        <a-table :dataSource="data" :columns="cols" :pagination="pagination" @change="table_change" style="margin-top: 10px;">
            <template #bodyCell="{ column, record }">
                <template v-if="column.key === 'category'">
                    <!-- <span v-if="record.category == 1">食品</span>
                    <span v-else-if="record.category == 2">药品</span>
                    <span v-else-if="record.category == 3">计量</span>
                    <span v-else-if="record.category == 4">电梯</span>
                    <span v-else-if="record.category == 5">虚假广告</span> -->
                    {{$store.state.category.category[record.category] ? $store.state.category.category[record.category] : '未分类'}}
                </template>
                <template v-else-if="column.key === 'type'">
                    <span v-if="record.type == 0">投诉</span>
                    <span v-else-if="record.type == 1">举报</span>
                </template>
                <template v-else-if="column.key === 'status'">
                    <span v-if="record.status == 0">不予受理</span>
                    <span v-else-if="record.status == 1">用户撤回</span>
                    <span v-else-if="record.status == 100">已提交</span>
                    <span v-else-if="record.status == 101">已受理</span>
                    <span v-else-if="record.status == 102">已完成</span>
                </template>
                <template v-else-if="column.key === 'action'">
                    <a-button type="primary" @click="toDetail(record.ID)">查看</a-button>
                </template>
            </template>
        </a-table>
    </div>
</template>

<script>
import { ref, onMounted, computed } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";
import axios from "axios";
import moment from "moment";
export default {
    methods: { },
    data () {
        return { };
    },
    setup() {
        const store = useStore();
        const router = useRouter();const pageSize = ref(20);
        const total = ref(100);
        const currentPage = ref(1);
        const pagination = computed(() => ({
            total: total.value,
            current: currentPage.value,
            pageSize: pageSize.value,
        }));
        const cols = ref([
            {
                title: '地址',
                dataIndex: 'location',
                key: 'location'
            },
            {
                title: '类型',
                dataIndex: 'type',
                key: 'type'
            },
            {
                title: '类别',
                dataIndex: 'category',
                key: 'category'
            },
            {
                title: '提交时间',
                dataIndex: 'time',
                key: 'time'
            },
            {
                title: '状态',
                dataIndex: 'status',
                key: 'status'
            },
            {
                title: '操作',
                key: 'action'
            }
        ]);

        const data = ref([
            {
                location: '测试地址1',
                category: 1,
                anonymous: 0,
                time: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
                status: 100,
                ID: '1',
                type: 0
            },
            {
                location: '河北省保定市莲池区恒祥北大街丽景蓝湾门口XXXX便利店',
                category: 1,
                anonymous: 1,
                time: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
                status: 101,
                ID: '2',
                type: 1
            },
            {
                location: '恒祥北大街丽景蓝湾门口便利店',
                category: 1,
                anonymous: 1,
                time: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
                status: 101,
                ID: '3',
                type: 1
            }
        ]);

        const searchLocation = ref('');
        const searchDate = ref(null);
        const searchStatus = ref(null);
        const searchID = ref('');
        const searchType = ref(null);
        const searchPhone = ref('');
        const searchAnonymous = ref(null);
        const statusOptions = ref([
            {
                value: 0,
                label: '不受理'
            },
            {
                value: 100,
                label: '已提交'
            },
            {
                value: 101,
                label: '已受理'
            },
            {
                value: 102,
                label: '已完成'
            }
        ]);
        const anonymousOption = ref([
            {
                value: 0,
                label: '否'
            },
            {
                value: 1,
                label: '是'
            }
        ]);
        const typeOption = ref([
            {
                value: 0,
                label: '投诉'
            },
            {
                value: 1,
                label: '举报'
            }
        ]);
        const categoryMap = ref(['', '食品', '药品', '计量', '电梯', '虚假广告']);

        function table_change(page) {
            if (page.current == currentPage.value && page.pageSize == pageSize.value) {
                return;
            } else {
                currentPage.value = page.current;
                pageSize.value = page.pageSize;
            }
        }

        function toDetail(ID) {
            router.push({ name: 'FiledDetail', params: {id: ID} });
        }

        onMounted(() => {
            store.dispatch({ type: 'setSelectedKeys', selectedKeys: ['Filed'] });
            store.dispatch({ type: 'setOpenKeys', openKeys: ['Filed'] });
        });

        return {
            pageSize,
            total,
            currentPage,
            pagination,
            cols,
            data,
            searchDate,
            searchLocation,
            searchStatus,
            searchID,
            searchType,
            searchPhone,
            searchAnonymous,
            statusOptions,
            anonymousOption,
            typeOption,
            categoryMap,
            table_change,
            toDetail,
        }
    }
}
</script>

<style>
#filed {
    width: 100%;
    text-align: left;
}

.rows {
    margin-bottom: 10px;
    height: 30px;
    font-size: 15px;
    border-bottom: 1px solid rgb(235, 237, 240);
}

.center {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
</style>
